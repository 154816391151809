import React, { useContext, useEffect, useState } from "react"
import Img from "../../../helpers/img"
import { Link } from "gatsby"
import StoreContext from "~/context/StoreContext"
import { head } from "lodash/fp"
import Lazyload from "react-lazyload"

const Product = ({
  name,
  variantName,
  image,
  imageAnimation,
  shopifyVariants,
  color,
  slug,
  uri,
  shopifyAvailableForSale,
  shopifyProductId,
  product_content,
  index,
}) => {
  const { price, compareAtPrice, shopifyId } = head(shopifyVariants)
  const { addVariantToCart, setIsCartOpen, isProductAvailable } = useContext(
    StoreContext
  )
  const [isAvailable, setIsAvailable] = useState(false)

  let txtColor =
    !!product_content && !!product_content.productType
      ? product_content.productType
      : ""

  useEffect(() => {
    async function checkAvailable() {
      setIsAvailable(await isProductAvailable(shopifyProductId))
    }
    checkAvailable()
  }, [])

  return (
    <Link
      to={uri}
      title={name}
      className={"product fadeInBottom " + txtColor}
      style={{
        animationDelay: `${0.15 * index}s`,
        animationDuration: "1s",
        opacity: 0,
        animationFillMode: "forwards",
      }}
    >
      <div className="background" style={{ backgroundColor: color }} />
      <span className="variant-big">{variantName}</span>
      <span className="variant-small">{variantName}</span>

      {/* {console.log(image, name)} */}

      {!!image && !!image.sourceUrl && (
        <div className={`imageWrapper ${product_content.productType}`}>
          <Lazyload>
            <Img
              classe="image"
              source={image.sourceUrl}
              alt={"Funky Veggie - Produit - " + name + " - " + variantName}
              // alt={name}
              width={296}
            />
          </Lazyload>
        </div>
      )}

      {/* {!!imageAnimation && !!imageAnimation.sourceUrl && (
        <div className={`imageWrapper secondImg ${product_content.productType}`}>
          <Lazyload>
            <Img
              classe="image"
              source={imageAnimation.sourceUrl}
              alt={name}
              width={296}
            />
          </Lazyload>
        </div>
      )} */}

      <span className="name">{name}</span>
      {shopifyVariants?.[0]?.compareAtPrice ? (
        <span className="oldPrice mobile">
          {`${shopifyVariants[0].compareAtPrice}€`}
          {/* {`${(((price - shopifyVariants[0].compareAtPrice) / shopifyVariants[0].compareAtPrice) * 100 ).toFixed(2)}%`} */}
        </span>
      ) : (
        ""
      )}
      <span className="price">{`${price} €`}</span>
      {isAvailable && (
        <button
          className="buy"
          onClick={e => {
            e.preventDefault()
            shopifyId && addVariantToCart(shopifyId, 1)
            setIsCartOpen(true)
          }}
        >
          Ajouter - {`${price} €`}
          {shopifyVariants?.[0]?.compareAtPrice ? (
            <span className="oldPrice">
              {`${shopifyVariants[0].compareAtPrice}€`}
            </span>
          ) : (
            ""
          )}
        </button>
      )}
    </Link>
  )
}

export default Product
